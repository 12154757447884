<template>
	<v-toolbar elevation="1" height="45" rounded="t">
		<v-sheet max-width="100%">
			<v-slide-group show-arrows>
				<v-slide-item v-for="m in menu" :key="m.link" v-slot="{ toggle }">
					<v-tooltip class="text-truncate" bottom color="primary">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click="toggle"
								v-bind="attrs"
								v-on="on"
								:to="m.to"
								color="primary"
								exact
								text
							>{{m.titulo}}</v-btn>
						</template>
						<span>{{m.tooltip}}</span>
					</v-tooltip>
				</v-slide-item>
			</v-slide-group>
		</v-sheet>
	</v-toolbar>
</template>

<script>
export default {
	name: "ToolbarContainer",
	props: ["menu"],
};
</script>