<template>
	<v-bottom-navigation
		app
		grow
		color="primary"
		fixed
		class="d-inline-flex d-md-none"
		v-model="value"
	>
		<v-slide-group show-arrows center-active>
			<v-slide-item v-for="m in menu" :key="m.link" v-slot="{ toggle }">
				<v-btn @click="toggle" :to="m.to" exact exact-active-class="font-weight-bold teal lighten-5">
					<v-icon>{{m.icon}}</v-icon>
					<span>{{m.titulo_app}}</span>
				</v-btn>
			</v-slide-item>
		</v-slide-group>
	</v-bottom-navigation>
</template>

<script>
export default {
	name: "BottomNavigationContainer",
	props: ["menu"],
	data: () => ({
		value: 0,
	}),
};
</script>